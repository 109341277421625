import AvatarEditor from "react-avatar-editor";
import React, {useEffect, useRef, useState} from "react";
import FileInput from "./FileInput/FileInput";
import {ImageCenterBox} from "@/components/ImageCenter";
import {imageToDataUri} from "@/services/imageUtilService";
import {Card} from "@/components/Card";
import {FiRotateCw, FiZoomIn, FiZoomOut} from "react-icons/fi";
import {Button} from "@/components/Button";
import {ButtonVariantProps} from "@/components/Button/Button";
import {useSiteMessage} from "@/providers/SiteMessageProvider";
import ImageUploadEditorStats from "@/components/ImageUploadEditorStats";
import {SiteMessageEnum} from "@/components/SiteMessage/SiteMessage";

interface AvatarEditSectionProps {
    onReset: () => void;
    onUpload: (image: any, name: string) => Promise<boolean>;
    setIsLoading: (key: boolean) => void;
    fileRef?: any;
    rounded?: boolean;
}

export default function ImageUploadEditor({
                                              onReset,
                                              onUpload,
                                              setIsLoading,
                                              fileRef,
                                              rounded = true
                                          }: AvatarEditSectionProps) {
    const editorRef = useRef<any>();

    const [startingImage, setStartingImage] = useState<{ image: string; name: string; } | null>(null);

    const [newImage, setNewImage] = useState<any>(null);

    const [zoomLevel, setZoomLevel] = useState<number>(1);

    const [rotate, setRotate] = useState<number>(0);

    const {setMessage} = useSiteMessage();

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        if (event.target.files && event.target.files.length > 0) {
            const image = event.target.files[0];
            const controlledImage = document.createElement('img');
            controlledImage.addEventListener('load', async () => {
                try {
                    const resizedImageData = await imageToDataUri(controlledImage, 500, 500);
                    if (resizedImageData) {
                        setStartingImage({image: resizedImageData, name: image.name});
                        setNewImage({image: resizedImageData, name: image.name});
                    }
                    setIsLoading(false);
                } catch (error: any) {
                    setIsLoading(false);
                }
            });
            const fileReader = new FileReader();
            fileReader.addEventListener('load', () => {
                controlledImage.src = fileReader.result as string;
            });
            fileReader.readAsDataURL(image);
        }
    };

    const onCancelLocal = () => {
        setNewImage(null);
        onReset();
    };

    const onResetLocal = () => {
        setZoomLevel(1);
        setRotate(0);
    };

    const zoomIn = () => {
        setIsLoading(true);
        setZoomLevel(() => {
            setIsLoading(false);
            return parseFloat(zoomLevel.toFixed(1)) < 5 ? zoomLevel + .1 : zoomLevel;
        });
    };

    const zoomOut = () => {
        setIsLoading(true);
        setZoomLevel(() => {
            setIsLoading(false);
            return parseFloat(zoomLevel.toFixed(1)) > 1 ? zoomLevel - .1 : zoomLevel;
        });
    };

    const rotate90 = () => {
        setRotate(rotate + 90);
    };

    const updateImage = () => {
        try {
            if (!editorRef?.current) return;

            setIsLoading(true);

            const canvas = editorRef.current.getImage();
            const dataUrl = canvas.toDataURL();

            setNewImage(({name}) => ({image: dataUrl, name}));

            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }

    const uploadHandler = async () => {
        if (!newImage?.image || !newImage?.name) {
            setIsLoading(false);
            setMessage('Please select an image to upload', SiteMessageEnum.error);
            return;
        };

        const response = await onUpload(newImage.image, newImage.name);

        if (response) {
            setMessage('Uploaded successfully');
        } else {
            setMessage('Failed at this time', SiteMessageEnum.error);
        }

        setIsLoading(false);
    }

    return (
        <Card>
            <div className="flex flex-col pb-2">
                <h4>Step 1: Choose an Image</h4>
                <FileInput name="profileImage"
                           type="file"
                           accept="image/*"
                           _ref={fileRef}
                           onChange={handleFileChange}/>
            </div>
            <div className="flex flex-row">
                <Button className="w-full" variant={'primary' as ButtonVariantProps.primary} type="reset"
                        onClick={onCancelLocal}>Cancel</Button>
            </div>
            {
                startingImage &&
                (
                    <>
                        <div className="flex flex-col pb-2 relative">
                            <h4>Step 2: Edit Image</h4>
                            <div className="relative justify-center flex-initial flex-col mx-auto w-full h-full">
                                {
                                    !startingImage &&
                                    <div
                                        className="top-0 bottom-0 mx-auto text-center w-[200px] h-[200px] bg-gray-700 z-[100] flex items-center justify-center">
                                        <p className="text-white">Click on &apos;Choose File&apos; to upload an image</p>
                                    </div>
                                }
                                {startingImage &&
                                    <ImageCenterBox>
                                        <AvatarEditor
                                            ref={editorRef}
                                            image={startingImage.image}
                                            width={200}
                                            height={200}
                                            border={10}
                                            borderRadius={rounded ? 100 : 0}
                                            color={[255, 215, 0, 0.8]}
                                            scale={zoomLevel}
                                            rotate={rotate}
                                            onImageReady={() => {
                                                updateImage();
                                            }}
                                            onImageChange={async () => {
                                                console.log('ran');
                                                updateImage();
                                            }}
                                        />
                                    </ImageCenterBox>
                                }
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 mt-4">
                            <ImageUploadEditorStats zoomLevel={zoomLevel} rotate={rotate}/>
                            <div className="flex flex-row">
                                <div className="flex flex-row flex-1">
                                    <Button disabled={newImage === null} onClick={zoomIn} type="button"><FiZoomIn/></Button>
                                    <Button disabled={newImage === null} onClick={zoomOut} type="button"><FiZoomOut/></Button>
                                    <Button disabled={newImage === null} onClick={rotate90} type="button"><FiRotateCw/></Button>
                                    <Button variant={'primary' as ButtonVariantProps.primary} type="reset"
                                            onClick={onResetLocal}>Reset</Button>
                                </div>
                            </div>
                            {newImage && <Button disabled={newImage === null}
                                                 onClick={uploadHandler}>Upload</Button>}
                        </div>
                    </>
                )
            }
        </Card>
    );
}