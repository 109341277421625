// credit https://stackoverflow.com/a/14731922
export function calculateAspectRatioFit(srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return {width: srcWidth * ratio, height: srcHeight * ratio};
}


export async function imageToDataUri(img: any, width?: number, height?: number): Promise<string> {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            reject('Invalid context');
            return;
        }

        // set its dimension to target size
        canvas.width = width || img.naturalWidth;
        canvas.height = height || img.naturalHeight;

        const {
            width: finalWidth,
            height: finalHeight
        } = calculateAspectRatioFit(canvas.width, canvas.height, width || 800, height || 800);

        // draw source image into the off-screen canvas:
        ctx.drawImage(img, 0, 0, Math.min(finalWidth, canvas.width), Math.min(finalHeight, canvas.height));

        try {
            resolve(String(canvas.toDataURL('base64')));
        } catch (error: any) {
            reject(String(error?.message));
        }
    });
}