import {useLayoutEffect} from "react";
import Input from "../Input/Input";
import cx from "classnames";

export interface FileInputProps {
    _ref?: any;
    className?: string;

    [arg: string]: any;
}

export default function FileInput({_ref: ref, src, className, ...rest}: FileInputProps) {
    useLayoutEffect(() => {
        if (ref?.current) {
            ref.current.reset = function () {
                ref.current.value = null;
            };

            ref.current.onchange = function () {
                if (!this.value) {

                    return;
                }
            }
        }
    });

    return (
        <Input className={cx("file:mr-4 file:py-2 file:rounded-sm file:border-0 file:text-sm file:font-semibold file:bg-primary file:text-black", className)} {...rest as any} type="file" src={src} ref={ref}/>
    );
}