import React, {CSSProperties, ReactNode} from "react";
import cx from "classnames";
import {Button} from "../Button";
import {ButtonVariantProps} from "../Button/Button";

export interface CardProps {
    children: ReactNode | ReactNode[];
    inline?: boolean;
    className?: string;
    rounded?: boolean;
    style?: CSSProperties;
    collapsable?: boolean;
    [key: string]: any;
}

export default function Card({children, inline = false, className, style, rounded = true, collapsable = false, ...rest}: CardProps) {
    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(collapsable);

    return (
        <div style={style} className={cx("text-white p-2 bg-secondary", {'rounded': rounded}, {'p-0 inline-block': inline}, {
            'max-h-80 overflow-hidden': isCollapsed
        }, className)} {...rest}>
            {children}
            {collapsable && <Button variant={ButtonVariantProps.primary} className="absolute right-0 bottom-2" onClick={() => setIsCollapsed(!isCollapsed)}>{isCollapsed ? "Show More" : "Show Less"}</Button>}
        </div>
    );
}