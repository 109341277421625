import {ReactElement} from "react";
import cx from "classnames";

export interface ImageCenterBoxProps {
    children: ReactElement | ReactElement[];
    className?: string;
    [arg: string]: any;
}

export default function MediaCenterBox({children, className, ...rest}: ImageCenterBoxProps) {
    return (
        <div className={cx('relative flex justify-center rounded overflow-hidden bg-dark-black items-center max-h-full', className)} {...rest}>{children}</div>
    );
}