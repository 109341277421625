import React, {useEffect} from 'react';
import {useRouter} from 'next/router';
import Link from 'next/link';
import cx from 'classnames';
import {Button} from '@/components/Button';
import {BiArrowFromLeft} from "react-icons/bi";
import Modal from "@/layout/Modal";

export interface HeaderProps {
    menu?: React.ReactNode;
    className?: string;
    hideHeaderLogo?: boolean;
    variant?: number;
    isSticky?: boolean;
}

export default function Header({
                                   menu,
                                   className,
                                   hideHeaderLogo = true,
                                   variant = 1,
                                   isSticky = true
                               }: HeaderProps) {
    const router = useRouter();
    const [currentVariant, setCurrentVariant] = React.useState(variant);
    const [navWidth, setNavWidth] = React.useState(500);
    const headerRef = React.useRef<HTMLDivElement>(null);
    const menuRef = React.useRef<HTMLDivElement>(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [isProfileMenuModalOpen, setIsProfileMenuModalOpen] = React.useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);

    const giveMore = () => alert('you got it!');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('variant')) {
            setCurrentVariant(Number(urlParams.get('variant')));
        }
    }, [window.location.search]);

    useEffect(() => {
        if (!menuRef.current) return;
        menuRef.current.onclick = toggleMenu;
        menuRef.current.style.display = menuOpen ? 'block' : 'none';
    }, [menuOpen, menuRef.current]);

    useEffect(() => {
        const setNavWidthHandler = () => {
            if (!headerRef.current) return;

            const navWidth = headerRef.current.clientWidth;

            setNavWidth(navWidth);
        }

        setNavWidthHandler();

        window.addEventListener('resize', setNavWidthHandler);
    }, [window.innerWidth]);

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                setMenuOpen(false);
            }
        };
        document.addEventListener('keyup', handleEscape);
        return () => document.removeEventListener('keyup', handleEscape);
    }, []);

    const renderDesign = () => {
        // Common menu button variations
        const navigationText = {
            explore: {default: "Explore", active: "Close"},
            discover: {default: "Discover", active: "Close"},
            journey: {default: "Journey", active: "Close"},
            wander: {default: "Wander", active: "Close"},
            flow: {default: "Flow", active: "Close"}
        };

        switch (currentVariant) {
            case 2:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef}
                                     className={cx("w-full absolute z-0 bg-secondary text-black flex flex-col hidden")}>
                                    <div className="relative mt-[60px] text-black">{menu}</div>
                                </div>
                                <div className="bg-pattern-3 bg-primary p-2">
                                    <div className="flex flex-row w-full relative z-1 gap-0 m-0 h-[60px] bg-secondary">
                                        <div onClick={() => router.push('/')}
                                             className="flex w-full items-center relative min-w-[120px] sm:min-w-[160px] max-w-[240px] overflow-x-hidden">
                                            {!hideHeaderLogo && (
                                                <Link href="/" className="relative z-[10] w-full h-full">
                                                    <img src="/imgs/full-logo.png" alt="logo"
                                                         className="absolute left-0 top-[50%] -translate-y-[50%] w-[200px] p-2 hidden sm:block"/>
                                                    <img src="/imgs/icon.png" alt="logo"
                                                         className="absolute left-0 max-h-full w-auto p-2 block sm:hidden"/>
                                                </Link>
                                            )}
                                        </div>
                                        <div className="flex w-full items-center relative">&nbsp;</div>
                                        <div
                                            className="min-w-[160px] max-w-[160px] h-full bg-primary flex items-center justify-center gap-2 cursor-pointer"
                                            onClick={toggleMenu}>
                                            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M4 6h16M4 12h16m-7 6h7"/>
                                            </svg>
                                            <span
                                                className={cx("text-xl whitespace-nowrap", {"border-b border-secondary/30": menuOpen})}>
                                                {!menuOpen ? 'Menu' : 'Close'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            case 3:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef}
                                     className={cx("w-full absolute z-0 bg-secondary text-black flex flex-col hidden")}>
                                    <div className="relative mt-[60px] text-black">{menu}</div>
                                </div>
                                <div className="flex flex-row w-full relative z-1 gap-0 m-0 h-[60px] bg-secondary">
                                    <div onClick={() => router.push('/')}
                                         className="flex w-full items-center relative min-w-[120px] sm:min-w-[160px] max-w-[240px] overflow-x-hidden">
                                        {!hideHeaderLogo && (
                                            <Link href="/" className="relative z-[10] w-full h-full">
                                                <img src="/imgs/full-logo.png" alt="logo"
                                                     className="absolute left-0 top-[50%] -translate-y-[50%] w-[200px] p-2 hidden sm:block"/>
                                                <img src="/imgs/icon.png" alt="logo"
                                                     className="absolute left-0 max-h-full w-auto p-2 block sm:hidden"/>
                                            </Link>
                                        )}
                                    </div>
                                    <div className="flex w-full items-center relative">&nbsp;</div>
                                    <div className="bg-pattern-3 bg-primary p-1">
                                        <div
                                            className="min-w-[160px] max-w-[160px] h-full bg-primary flex items-center justify-center gap-2 cursor-pointer"
                                            onClick={toggleMenu}>
                                            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M4 6h16M4 12h16m-7 6h7"/>
                                            </svg>
                                            <span
                                                className={cx("text-xl whitespace-nowrap", {"border-b border-secondary/30": menuOpen})}>
                                                {!menuOpen ? 'Menu' : 'Close'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            case 6: // Gradient Edge
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {stuck: isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef}
                                     className={cx("w-full absolute z-0 bg-secondary text-black flex flex-col hidden")}>
                                    <div className="relative mt-[60px] text-black">{menu}</div>
                                </div>
                                <div className="bg-gradient-to-r from-primary via-primary to-secondary p-0.5">
                                    <div className="flex flex-row w-full relative z-1 gap-0 m-0 h-[60px] bg-secondary">
                                        <div onClick={() => router.push('/')}
                                             className="flex items-center relative min-w-[120px] sm:min-w-[160px] max-w-[240px] overflow-x-hidden">
                                            {!hideHeaderLogo && (
                                                <Link href="/" className="relative z-[10] w-full h-full">
                                                    <img src="/imgs/full-logo.png" alt="logo"
                                                         className="absolute left-0 top-[50%] -translate-y-[50%] w-[200px] p-2 hidden sm:block"/>
                                                    <img src="/imgs/icon.png" alt="logo"
                                                         className="absolute left-0 max-h-full w-auto p-2 block sm:hidden"/>
                                                </Link>
                                            )}
                                        </div>
                                        <div className="flex w-full items-center relative">&nbsp;</div>
                                        <div
                                            className="min-w-[160px] max-w-[160px] h-full bg-primary flex items-center justify-center gap-2 cursor-pointer"
                                            onClick={toggleMenu}>
                                            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M4 6h16M4 12h16m-7 6h7"/>
                                            </svg>
                                            <span
                                                className={cx("text-xl whitespace-nowrap", {"border-b border-secondary/30": menuOpen})}>
                                    {!menuOpen ? 'Menu' : 'Close'}
                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            // Tertiary Accent with Split Design
            case 7:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef}
                                     className={cx("w-full absolute z-0 bg-secondary text-black flex flex-col hidden")}>
                                    <div className="relative mt-[60px] text-black">{menu}</div>
                                </div>
                                <div className="flex flex-row w-full relative z-1 gap-0 m-0 h-[60px]">
                                    <div
                                        className="absolute inset-0 bg-gradient-to-r from-tertiary/10 via-tertiary/5 to-transparent"/>
                                    <div onClick={() => router.push('/')}
                                         className="flex w-full items-center relative min-w-[120px] sm:min-w-[160px] max-w-[240px]
                                      overflow-x-hidden bg-primary border-r border-tertiary/20">
                                        {!hideHeaderLogo && (
                                            <Link href="/" className="relative z-[10] w-full h-full">
                                                <img src="/imgs/full-logo.png" alt="logo"
                                                     className="absolute left-0 top-[50%] -translate-y-[50%] w-[200px] p-2 hidden sm:block"/>
                                                <img src="/imgs/icon.png" alt="logo"
                                                     className="absolute left-0 max-h-full w-auto p-2 block sm:hidden"/>
                                            </Link>
                                        )}
                                    </div>
                                    <div className="flex w-full items-center relative">
                                        <div className="absolute inset-0 bg-secondary/5"/>
                                        &nbsp;
                                    </div>
                                    <div className="min-w-[160px] max-w-[160px] h-full bg-tertiary flex items-center justify-center gap-2
                                      cursor-pointer hover:bg-tertiary/90 transition-colors"
                                         onClick={toggleMenu}>
                                        <svg className="w-6 h-6 text-white" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M4 6h16M4 12h16m-7 6h7"/>
                                        </svg>
                                        <span className={cx("text-xl whitespace-nowrap text-white", {
                                            "border-b border-white/30": menuOpen
                                        })}>
                                {!menuOpen ? 'Menu' : 'Close'}
                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Layered Tertiary Design
            case 8:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef}
                                     className={cx("w-full absolute z-0 bg-secondary text-black flex flex-col hidden")}>
                                    <div className="relative mt-[60px] text-black">{menu}</div>
                                </div>
                                <div className="relative">
                                    <div className="absolute top-0 w-full h-1 bg-tertiary/30"/>
                                    <div className="flex flex-row w-full relative z-1 gap-0 m-0 h-[60px] bg-primary">
                                        <div onClick={() => router.push('/')}
                                             className="flex w-full items-center relative min-w-[120px] sm:min-w-[160px] max-w-[240px]
                                          overflow-x-hidden">
                                            {!hideHeaderLogo && (
                                                <Link href="/" className="relative z-[10] w-full h-full">
                                                    <img src="/imgs/full-logo.png" alt="logo"
                                                         className="absolute left-0 top-[50%] -translate-y-[50%] w-[200px] p-2 hidden sm:block"/>
                                                    <img src="/imgs/icon.png" alt="logo"
                                                         className="absolute left-0 max-h-full w-auto p-2 block sm:hidden"/>
                                                </Link>
                                            )}
                                        </div>
                                        <div className="flex w-full items-center relative">
                                            <div className="absolute inset-0 bg-tertiary/5"/>
                                            &nbsp;
                                        </div>
                                        <div className="relative">
                                            <div
                                                className="absolute -left-3 top-0 h-full w-6 bg-tertiary/20 transform -skew-x-12"/>
                                            <div className="min-w-[160px] max-w-[160px] h-full bg-secondary flex items-center justify-center gap-2
                                              cursor-pointer hover:bg-secondary/90 transition-colors"
                                                 onClick={toggleMenu}>
                                                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none"
                                                     stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M4 6h16M4 12h16m-7 6h7"/>
                                                </svg>
                                                <span className={cx("text-xl whitespace-nowrap", {
                                                    "border-b border-tertiary": menuOpen
                                                })}>
                                        {!menuOpen ? 'Menu' : 'Close'}
                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            case 9:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef}
                                     className={cx(
                                         "w-full absolute z-10 flex flex-col hidden transform transition-all duration-300",
                                         menuOpen ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"
                                     )}>
                                    <div className="relative bg-secondary shadow-lg">
                                        <div className="max-w-screen-xl mx-auto">
                                            <div className="relative mt-16 bg-tertiary/5 p-6">{menu}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="max-w-screen-xl mx-auto">
                                    <div className="relative flex items-center justify-between h-16">
                                        <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                            <button onClick={toggleMenu}
                                                    className="inline-flex items-center justify-center p-2 rounded-md
                                                 text-tertiary hover:text-primary hover:bg-tertiary/10
                                                 transition-colors duration-200">
                                                <svg className={cx("h-6 w-6 transition-transform duration-200", {
                                                    "transform rotate-90": menuOpen
                                                })}
                                                     fill="none"
                                                     viewBox="0 0 24 24"
                                                     stroke="currentColor">
                                                    <path strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth={2}
                                                          d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}/>
                                                </svg>
                                            </button>
                                        </div>

                                        <div
                                            className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                            <div className="flex-shrink-0 flex items-center">
                                                {!hideHeaderLogo && (
                                                    <Link href="/" className="block relative">
                                                        <img src="/imgs/full-logo.png"
                                                             alt="logo"
                                                             className="h-8 w-auto hidden sm:block"/>
                                                        <img src="/imgs/icon.png"
                                                             alt="logo"
                                                             className="h-8 w-auto block sm:hidden"/>
                                                    </Link>
                                                )}
                                            </div>
                                        </div>

                                        <div className="hidden sm:flex items-center pr-2 sm:pr-0">
                                            <div className="relative">
                                                <button onClick={toggleMenu}
                                                        className="group bg-secondary hover:bg-tertiary py-2 px-4 rounded-full
                                                     flex items-center space-x-2 transition-all duration-300
                                                     hover:pr-8">
                                        <span className="relative">
                                            <span className={cx(
                                                "inline-block transition-all duration-300",
                                                menuOpen ? "opacity-0 translate-x-2" : "opacity-100"
                                            )}>
                                                Menu
                                            </span>
                                            <span className={cx(
                                                "absolute left-0 top-0 transition-all duration-300",
                                                menuOpen ? "opacity-100" : "opacity-0 -translate-x-2"
                                            )}>
                                                Close
                                            </span>
                                        </span>
                                                    <svg className={cx(
                                                        "w-5 h-5 transition-transform duration-300",
                                                        menuOpen ? "rotate-45" : "group-hover:rotate-90"
                                                    )}
                                                         viewBox="0 0 24 24"
                                                         fill="none"
                                                         stroke="currentColor"
                                                         strokeWidth="2">
                                                        <path strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                              d="M12 4v16m8-8H4"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            case 10:
                return (
                    <section className="mb-8 overflow-hidden">
                        <div ref={headerRef} className={cx("w-full max-w-full relative", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                {/* Menu Panel */}
                                <div ref={menuRef}
                                     className={cx("w-full absolute z-20 flex flex-col hidden transform transition-all duration-500",
                                         "bg-gradient-to-br from-tertiary via-primary to-secondary")}>
                                    <div className="relative mt-[120px] p-8">{menu}</div>
                                </div>

                                {/* Main Header Container */}
                                <div className="relative">
                                    {/* Decorative Background Elements */}
                                    <div className="absolute inset-0 overflow-hidden">
                                        <div
                                            className="absolute -top-1/2 -right-1/4 w-1/2 h-[500px] bg-tertiary/10 rounded-full blur-3xl"/>
                                        <div
                                            className="absolute -bottom-1/2 -left-1/4 w-1/2 h-[500px] bg-primary/10 rounded-full blur-3xl"/>
                                    </div>

                                    {/* Content Grid */}
                                    <div className="relative grid grid-cols-12 gap-4 items-center h-[120px] px-6">
                                        {/* Logo Area - Floating in space */}
                                        <div className="col-span-3 col-start-2">
                                            {!hideHeaderLogo && (
                                                <Link href="/"
                                                      className="block transform hover:scale-105 transition-transform duration-300
                                                   relative z-10 bg-secondary/5 rounded-full p-2">
                                                    <img src="/imgs/full-logo.png"
                                                         alt="logo"
                                                         className="h-12 w-auto hidden sm:block"/>
                                                    <img src="/imgs/icon.png"
                                                         alt="logo"
                                                         className="h-12 w-auto block sm:hidden"/>
                                                </Link>
                                            )}
                                        </div>

                                        {/* Central Design Element */}
                                        <div className="col-span-4 col-start-5 h-full flex items-center justify-center">
                                            <div className="relative w-full h-1/2">
                                                <div
                                                    className="absolute inset-0 bg-gradient-to-r from-transparent via-tertiary/20 to-transparent"/>
                                                <div
                                                    className="absolute inset-y-0 left-0 w-1/4 bg-gradient-to-r from-primary/20 to-transparent"/>
                                                <div
                                                    className="absolute inset-y-0 right-0 w-1/4 bg-gradient-to-l from-secondary/20 to-transparent"/>
                                            </div>
                                        </div>

                                        {/* Menu Control - Otherworldly Interface */}
                                        <div className="col-span-2 col-start-10">
                                            <button onClick={toggleMenu}
                                                    className="group relative w-full aspect-square max-w-[100px] rounded-full
                                                 overflow-hidden transition-all duration-300
                                                 hover:shadow-[0_0_30px_rgba(var(--tertiary-rgb),0.3)]">
                                                {/* Animated Background */}
                                                <div className="absolute inset-0 bg-gradient-to-br from-primary via-secondary to-tertiary
                                                    opacity-75 group-hover:opacity-100 transition-opacity duration-300"/>

                                                {/* Center Content */}
                                                <div
                                                    className="relative h-full flex flex-col items-center justify-center">
                                                    <div className={cx("transition-all duration-500 transform",
                                                        menuOpen ? "rotate-45 scale-110" : "rotate-0 scale-100")}>
                                                        <div className="w-8 h-0.5 bg-white mb-2 transition-all"/>
                                                        <div className="w-8 h-0.5 bg-white"/>
                                                    </div>
                                                    <span className="mt-2 text-sm text-white font-light tracking-wider">
                                            {menuOpen ? 'CLOSE' : 'MENU'}
                                        </span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            case 11:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                {/* Menu Overlay */}
                                <div ref={menuRef}
                                     className={cx("w-full absolute z-20 hidden")}>
                                    <div className="relative mt-[80px] bg-white/90 backdrop-blur-sm shadow-lg">
                                        <div className="container mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                {/* Main Header */}
                                <div className="bg-gradient-to-b from-primary/95 to-primary">
                                    <div className="container mx-auto">
                                        <div className="flex justify-between items-center h-[80px] px-4">
                                            {/* Left Side - Logo */}
                                            <div className="relative z-10">
                                                {!hideHeaderLogo && (
                                                    <Link href="/"
                                                          className="block transition-transform hover:-translate-y-0.5">
                                                        <img src="/imgs/full-logo.png"
                                                             alt="logo"
                                                             className="h-10 w-auto hidden sm:block"/>
                                                        <img src="/imgs/icon.png"
                                                             alt="logo"
                                                             className="h-10 w-auto block sm:hidden"/>
                                                    </Link>
                                                )}
                                            </div>

                                            {/* Right Side - Menu Trigger */}
                                            <div className="relative">
                                                <button onClick={toggleMenu}
                                                        className="relative group bg-tertiary/90 hover:bg-tertiary px-6 py-2
                                                     rounded-full transition-colors">
                                                    <div className="flex items-center space-x-3">
                                            <span className="text-white text-sm tracking-wider">
                                                {menuOpen ? 'CLOSE' : 'MENU'}
                                            </span>
                                                        <div className="relative w-5 h-5">
                                                            <div className={cx(
                                                                "absolute top-1/2 left-0 w-5 h-0.5 bg-white transform -translate-y-1/2 transition-transform",
                                                                menuOpen ? "rotate-45" : "rotate-0"
                                                            )}/>
                                                            <div className={cx(
                                                                "absolute top-1/2 left-0 w-5 h-0.5 bg-white transform -translate-y-1/2 transition-transform",
                                                                menuOpen ? "-rotate-45" : "rotate-0"
                                                            )}/>
                                                        </div>
                                                    </div>

                                                    {/* Hover Effect Ring */}
                                                    <div className="absolute -inset-0.5 bg-white rounded-full opacity-0
                                                      group-hover:opacity-20 transition-opacity"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );


            case 13:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                {/* Menu Content Area */}
                                <div ref={menuRef}
                                     className={cx("w-full absolute z-20 hidden transform")}>
                                    <div className="bg-primary">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                {/* Main Structure */}
                                <div className="bg-primary">
                                    <div className="max-w-6xl mx-auto px-4">
                                        <div className="grid grid-cols-3 h-[90px]">
                                            {/* Interactive Area */}
                                            <div className="flex items-center justify-end col-start-3">
                                                <button onClick={toggleMenu}
                                                        className="bg-tertiary hover:bg-tertiary/90 transition-colors p-4 relative">
                                                    <div className="flex flex-col gap-1.5 items-center">
                                                        <span
                                                            className="block w-6 h-0.5 bg-white transition-transform"/>
                                                        <span
                                                            className="block w-6 h-0.5 bg-white transition-transform"/>
                                                    </div>
                                                </button>
                                            </div>

                                            {/* Identity Area */}
                                            <div className="flex items-center justify-center col-start-2">
                                                {!hideHeaderLogo && (
                                                    <Link href="/"
                                                          className="block bg-secondary p-3 hover:bg-secondary/90 transition-colors">
                                                        <img src="/imgs/full-logo.png"
                                                             alt="logo"
                                                             className="h-12 w-auto hidden sm:block"/>
                                                        <img src="/imgs/icon.png"
                                                             alt="logo"
                                                             className="h-12 w-auto block sm:hidden"/>
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            // Variation A - Diagonal Split
            case 14:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary/95 backdrop-blur-sm shadow-lg mt-[70px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="relative h-[70px] bg-primary overflow-hidden">
                                    <div className="absolute inset-0 bg-secondary skew-x-[-20deg] translate-x-[-10%]">
                                        {!hideHeaderLogo && (
                                            <div
                                                className="absolute left-[20%] top-1/2 -translate-y-1/2 -skew-x-[20deg]">
                                                <Link href="/" className="block">
                                                    <img src="/imgs/full-logo.png" alt="logo"
                                                         className="h-10 w-auto hidden sm:block"/>
                                                    <img src="/imgs/icon.png" alt="logo"
                                                         className="h-10 w-auto block sm:hidden"/>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                    <div className="absolute right-8 top-1/2 -translate-y-1/2">
                                        <button onClick={toggleMenu}
                                                className="bg-tertiary px-6 py-2 hover:bg-tertiary/90 transition-colors">
                                            <span className="text-white">{menuOpen ? 'CLOSE' : 'MENU'}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Variation B - Stacked Elements
            case 15:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-primary/95 backdrop-blur-sm mt-[90px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-secondary">
                                    <div className="max-w-6xl mx-auto flex flex-col items-center py-3">
                                        {!hideHeaderLogo && (
                                            <Link href="/" className="block mb-2">
                                                <img src="/imgs/full-logo.png" alt="logo"
                                                     className="h-8 w-auto hidden sm:block"/>
                                                <img src="/imgs/icon.png" alt="logo"
                                                     className="h-8 w-auto block sm:hidden"/>
                                            </Link>
                                        )}
                                        <button onClick={toggleMenu}
                                                className="bg-tertiary px-4 py-1 text-sm hover:bg-tertiary/90 transition-colors">
                                            <span className="text-white">{menuOpen ? 'CLOSE' : 'MENU'}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Variation cards - Side Accent
            case 16:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-primary mt-[80px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-secondary relative h-[80px]">
                                    <div className="absolute inset-y-0 right-0 w-1 bg-tertiary"/>
                                    <div className="max-w-6xl mx-auto h-full flex items-center justify-between px-8">
                                        {!hideHeaderLogo && (
                                            <Link href="/" className="block">
                                                <img src="/imgs/full-logo.png" alt="logo"
                                                     className="h-12 w-auto hidden sm:block"/>
                                                <img src="/imgs/icon.png" alt="logo"
                                                     className="h-12 w-auto block sm:hidden"/>
                                            </Link>
                                        )}
                                        <button onClick={toggleMenu}
                                                className="flex items-center space-x-2 hover:text-tertiary transition-colors">
                                            <span>{menuOpen ? 'CLOSE' : 'MENU'}</span>
                                            <div className="w-8 h-8 flex flex-col justify-center gap-1.5">
                                                <span className="block w-full h-0.5 bg-current"/>
                                                <span className="block w-full h-0.5 bg-current"/>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            // Variation D - Corner Focus
            case 17:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[75px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-primary h-[75px] relative">
                                    <div className="max-w-6xl mx-auto h-full grid grid-cols-4">
                                        <div className="bg-tertiary col-span-1">
                                            {!hideHeaderLogo && (
                                                <Link href="/" className="block h-full p-4">
                                                    <img src="/imgs/full-logo.png" alt="logo"
                                                         className="h-full w-auto hidden sm:block"/>
                                                    <img src="/imgs/icon.png" alt="logo"
                                                         className="h-full w-auto block sm:hidden"/>
                                                </Link>
                                            )}
                                        </div>
                                        <div className="col-span-2"/>
                                        <div className="col-span-1 flex items-center justify-center">
                                            <button onClick={toggleMenu}
                                                    className="px-6 py-2 bg-secondary/10 hover:bg-secondary/20 transition-colors">
                                                <span className="text-white">{menuOpen ? 'CLOSE' : 'MENU'}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Variation E - Alternating Bands
            case 18:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-primary mt-[80px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-primary h-[80px]">
                                    <div className="max-w-6xl mx-auto h-full flex">
                                        <div className="w-1/3 bg-secondary flex items-center justify-center">
                                            {!hideHeaderLogo && (
                                                <Link href="/" className="block p-4">
                                                    <img src="/imgs/full-logo.png" alt="logo"
                                                         className="h-10 w-auto hidden sm:block"/>
                                                    <img src="/imgs/icon.png" alt="logo"
                                                         className="h-10 w-auto block sm:hidden"/>
                                                </Link>
                                            )}
                                        </div>
                                        <div className="w-1/3 bg-tertiary/20"/>
                                        <div className="w-1/3 bg-primary flex items-center justify-center">
                                            <button onClick={toggleMenu}
                                                    className="flex items-center space-x-3 hover:opacity-80 transition-opacity">
                                                <span className="text-white">{menuOpen ? 'CLOSE' : 'MENU'}</span>
                                                <div className="w-5 h-5 flex flex-col justify-center gap-1">
                                                    <span className="block w-full h-0.5 bg-white"/>
                                                    <span className="block w-full h-0.5 bg-white"/>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Variation F - Asymmetric Split
            case 19:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[70px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="h-[70px] bg-primary relative">
                                    <div className="max-w-6xl mx-auto h-full flex">
                                        <div className="w-2/3 bg-tertiary flex items-center">
                                            <div className="pl-12">
                                                {!hideHeaderLogo && (
                                                    <Link href="/" className="block">
                                                        <img src="/imgs/full-logo.png" alt="logo"
                                                             className="h-8 w-auto hidden sm:block"/>
                                                        <img src="/imgs/icon.png" alt="logo"
                                                             className="h-8 w-auto block sm:hidden"/>
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                        <div className="w-1/3 bg-primary flex items-center justify-end pr-12">
                                            <button onClick={toggleMenu}
                                                    className="border border-secondary/20 px-6 py-2 hover:bg-secondary/10 transition-colors">
                                    <span className="text-white text-sm tracking-wider">
                                        {menuOpen ? 'CLOSE' : 'MENU'}
                                    </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            // Variation F2 - Refined Asymmetric
            case 20:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[70px] shadow-lg">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="h-[70px] bg-primary relative">
                                    <div className="max-w-6xl mx-auto h-full flex">
                                        <div className="w-2/3 bg-tertiary flex items-center">
                                            <div className="pl-12 relative">
                                                <div className="absolute inset-y-0 right-0 w-[1px] bg-primary/10"/>
                                                {!hideHeaderLogo && (
                                                    <Link href="/"
                                                          className="block transition-opacity hover:opacity-90">
                                                        <img src="/imgs/full-logo.png"
                                                             alt="logo"
                                                             className="h-8 w-auto hidden sm:block"/>
                                                        <img src="/imgs/icon.png"
                                                             alt="logo"
                                                             className="h-8 w-auto block sm:hidden"/>
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                        <div className="w-1/3 bg-primary flex items-center justify-end pr-12">
                                            <button onClick={toggleMenu}
                                                    className="group relative flex items-center gap-3 px-6 py-2">
                                    <span className="text-white text-sm tracking-wider">
                                        {menuOpen ? 'CLOSE' : 'MENU'}
                                    </span>
                                                <div className="flex flex-col gap-1.5">
                                        <span className={cx(
                                            "block w-5 h-0.5 bg-white transform transition-transform origin-right",
                                            menuOpen ? "rotate-45" : "group-hover:w-4"
                                        )}/>
                                                    <span className={cx(
                                                        "block h-0.5 bg-white transform transition-transform origin-right",
                                                        menuOpen ? "w-5 -rotate-45" : "w-4 group-hover:w-5"
                                                    )}/>
                                                </div>
                                                <div className="absolute inset-0 border border-secondary/20 transform transition-transform
                                                  group-hover:scale-95"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            case 21:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[80px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[80px] flex flex-col items-center justify-center relative">
                                            {/* Logo Center */}
                                            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                                              bg-secondary px-8 py-3">
                                                {!hideHeaderLogo && (
                                                    <Link href="/" className="block">
                                                        <img src="/imgs/full-logo.png"
                                                             alt="logo"
                                                             className="h-8 w-auto hidden sm:block"/>
                                                        <img src="/imgs/icon.png"
                                                             alt="logo"
                                                             className="h-8 w-auto block sm:hidden"/>
                                                    </Link>
                                                )}
                                            </div>

                                            {/* Menu Button Right */}
                                            <div className="absolute right-8 top-1/2 -translate-y-1/2">
                                                <button onClick={toggleMenu}
                                                        className="bg-tertiary px-6 py-2 hover:bg-tertiary/90
                                                     transition-colors flex items-center gap-3">
                                        <span className="text-white text-sm tracking-wider">
                                            {menuOpen ? 'CLOSE' : 'MENU'}
                                        </span>
                                                    <div className="flex flex-col gap-1.5">
                                                        <span className="block w-5 h-0.5 bg-white"/>
                                                        <span className="block w-5 h-0.5 bg-white"/>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Variation A - Clean Integration
            case 22:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[80px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[80px] flex items-center justify-center">
                                            <div className="bg-secondary px-8 py-3 flex items-center gap-6">
                                                {!hideHeaderLogo && (
                                                    <Link href="/" className="block">
                                                        <img src="/imgs/full-logo.png" alt="logo"
                                                             className="h-8 w-auto hidden sm:block"/>
                                                        <img src="/imgs/icon.png" alt="logo"
                                                             className="h-8 w-auto block sm:hidden"/>
                                                    </Link>
                                                )}
                                                <button onClick={toggleMenu}
                                                        className="text-tertiary hover:text-tertiary/90 transition-colors flex items-center gap-2">
                                        <span className="text-sm tracking-wider">
                                            {menuOpen ? navigationText.explore.active : navigationText.explore.default}
                                        </span>
                                                    <div className="w-4 h-4 flex flex-col justify-center gap-1">
                                                        <span className="block w-full h-0.5 bg-current"/>
                                                        <span className="block w-full h-0.5 bg-current"/>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Variation B - Subtle Separator
            case 23:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[80px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[80px] flex items-center justify-center">
                                            <div className="bg-secondary px-8 py-3 flex items-center">
                                                {!hideHeaderLogo && (
                                                    <Link href="/" className="block">
                                                        <img src="/imgs/full-logo.png" alt="logo"
                                                             className="h-8 w-auto hidden sm:block"/>
                                                        <img src="/imgs/icon.png" alt="logo"
                                                             className="h-8 w-auto block sm:hidden"/>
                                                    </Link>
                                                )}
                                                <div className="mx-4 w-px h-8 bg-tertiary/20"/>
                                                <button onClick={toggleMenu}
                                                        className="group flex items-center gap-3">
                                        <span
                                            className="text-sm tracking-wider text-tertiary group-hover:text-tertiary/90 transition-colors">
                                            {menuOpen ? navigationText.discover.active : navigationText.discover.default}
                                        </span>
                                                    <div className="flex flex-col gap-1">
                                                        <span
                                                            className="block w-4 h-0.5 bg-tertiary group-hover:w-5 transition-all"/>
                                                        <span
                                                            className="block w-5 h-0.5 bg-tertiary group-hover:w-4 transition-all"/>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Variation cards - Pill Design
            case 24:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[80px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[80px] flex items-center justify-center">
                                            <div
                                                className="bg-secondary rounded-full px-8 py-3 flex items-center gap-4">
                                                {!hideHeaderLogo && (
                                                    <Link href="/" className="block">
                                                        <img src="/imgs/full-logo.png" alt="logo"
                                                             className="h-8 w-auto hidden sm:block"/>
                                                        <img src="/imgs/icon.png" alt="logo"
                                                             className="h-8 w-auto block sm:hidden"/>
                                                    </Link>
                                                )}
                                                <button onClick={toggleMenu}
                                                        className="bg-tertiary rounded-full px-4 py-1.5 flex items-center gap-2
                                                     hover:bg-tertiary/90 transition-colors">
                                        <span className="text-sm text-white tracking-wider">
                                            {menuOpen ? navigationText.journey.active : navigationText.journey.default}
                                        </span>
                                                    <div className="w-4 h-4 flex flex-col justify-center gap-1">
                                                        <span className="block w-full h-0.5 bg-white"/>
                                                        <span className="block w-full h-0.5 bg-white"/>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Variation D - Floating Elements
            case 25:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[90px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[90px] flex items-center justify-center relative">
                                            <div className="bg-secondary py-3 px-8 flex items-center gap-4 relative">
                                                <div className="absolute inset-0 bg-tertiary/5"/>
                                                {!hideHeaderLogo && (
                                                    <Link href="/" className="relative block">
                                                        <img src="/imgs/full-logo.png" alt="logo"
                                                             className="h-8 w-auto hidden sm:block"/>
                                                        <img src="/imgs/icon.png" alt="logo"
                                                             className="h-8 w-auto block sm:hidden"/>
                                                    </Link>
                                                )}
                                                <button onClick={toggleMenu}
                                                        className="relative group flex items-center gap-3">
                                        <span
                                            className="text-sm tracking-wider text-tertiary group-hover:text-tertiary/90 transition-colors">
                                            {menuOpen ? navigationText.wander.active : navigationText.wander.default}
                                        </span>
                                                    <div
                                                        className={cx("flex flex-col gap-1 transition-transform duration-300", {
                                                            "rotate-90": menuOpen
                                                        })}>
                                                        <span className="block w-4 h-0.5 bg-tertiary"/>
                                                        <span className="block w-4 h-0.5 bg-tertiary"/>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Variation E - Unified Design
            case 26:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[80px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[80px] flex items-center justify-center">
                                            <div className="bg-secondary flex items-center">
                                                {!hideHeaderLogo && (
                                                    <Link href="/"
                                                          className="block px-6 py-3 bg-secondary hover:bg-secondary/90 transition-colors">
                                                        <img src="/imgs/full-logo.png" alt="logo"
                                                             className="h-8 w-auto hidden sm:block"/>
                                                        <img src="/imgs/icon.png" alt="logo"
                                                             className="h-8 w-auto block sm:hidden"/>
                                                    </Link>
                                                )}
                                                <button onClick={toggleMenu}
                                                        className="h-full px-6 py-3 bg-tertiary hover:bg-tertiary/90
                                                     transition-colors flex items-center gap-3">
                                        <span className="text-sm text-white tracking-wider">
                                            {menuOpen ? navigationText.flow.active : navigationText.flow.default}
                                        </span>
                                                    <div className="w-4 h-4 flex flex-col justify-center gap-1">
                                                        <span className="block w-full h-0.5 bg-white"/>
                                                        <span className="block w-full h-0.5 bg-white"/>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            // Stabilized Pill Header - Taller Version
            case 27:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[100px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[100px] flex items-center justify-center">
                                            {/* Fixed width container to prevent layout shifts */}
                                            <div className="w-[320px] sm:w-[420px] bg-secondary rounded-full
                                              px-8 py-4 flex items-center justify-between">
                                                {!hideHeaderLogo && (
                                                    <Link href="/" className="block w-[120px] sm:w-[160px]">
                                                        <img src="/imgs/full-logo.png" alt="logo"
                                                             className="h-10 w-auto hidden sm:block"/>
                                                        <img src="/imgs/icon.png" alt="logo"
                                                             className="h-10 w-auto block sm:hidden"/>
                                                    </Link>
                                                )}
                                                {/* Fixed width button to prevent text shift */}
                                                <div className="w-[120px]">
                                                    <button onClick={toggleMenu}
                                                            className="w-full bg-tertiary rounded-full px-4 py-2
                                                         flex items-center justify-center gap-2
                                                         hover:bg-tertiary/90 transition-colors">
                                            <span
                                                className="text-sm text-white tracking-wider min-w-[60px] text-center">
                                                {menuOpen ? "Close" : "Journey"}
                                            </span>
                                                        <div className="w-4 h-4 flex flex-col justify-center gap-1">
                                                            <span className="block w-full h-0.5 bg-white"/>
                                                            <span className="block w-full h-0.5 bg-white"/>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Bordered Pill Header
            case 28:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[100px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary p-4">
                                    <div className="max-w-6xl mx-auto border-2 border-secondary/20 rounded-2xl">
                                        <div className="h-[100px] flex items-center justify-center">
                                            {/* Fixed width container to prevent layout shifts */}
                                            <div className="w-[320px] sm:w-[420px] bg-secondary rounded-full
                                              px-8 py-4 flex items-center justify-between">
                                                {!hideHeaderLogo && (
                                                    <Link href="/" className="block w-[120px] sm:w-[160px]">
                                                        <img src="/imgs/full-logo.png" alt="logo"
                                                             className="h-10 w-auto hidden sm:block"/>
                                                        <img src="/imgs/icon.png" alt="logo"
                                                             className="h-10 w-auto block sm:hidden"/>
                                                    </Link>
                                                )}
                                                {/* Fixed width button to prevent text shift */}
                                                <div className="w-[120px]">
                                                    <button onClick={toggleMenu}
                                                            className="w-full bg-tertiary rounded-full px-4 py-2
                                                         flex items-center justify-center gap-2
                                                         hover:bg-tertiary/90 transition-colors">
                                            <span
                                                className="text-sm text-white tracking-wider min-w-[60px] text-center">
                                                {menuOpen ? "Close" : "Journey"}
                                            </span>
                                                        <div className="w-4 h-4 flex flex-col justify-center gap-1">
                                                            <span className="block w-full h-0.5 bg-white"/>
                                                            <span className="block w-full h-0.5 bg-white"/>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            case 29:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[100px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[100px] flex items-center justify-center">
                                            {/* Fixed width container to prevent layout shifts */}
                                            <div
                                                className="w-[320px] sm:w-[420px] bg-secondary rounded-full px-8 py-4 flex items-center justify-between">
                                                {!hideHeaderLogo && (
                                                    <div className="relative">
                                                        <Link href="/" className="block w-[120px] sm:w-[160px]">
                                                            <img src="/imgs/full-logo.png" alt="logo"
                                                                 className="max-h-10 h-full w-auto hidden sm:block"/>
                                                            <img src="/imgs/icon.png" alt="logo"
                                                                 className="max-h-10 h-full w-auto block sm:hidden"/>
                                                        </Link>
                                                        <Button onClick={toggleMenu}
                                                                className="absolute top-[50%] bg-tertiary pt-[2px] border-none transform -translate-y-[50%] rounded-sm -right-10 h-6 w-8 flex flex-col justify-center">
                                                            <>
                                                                <div
                                                                    className="block w-full max-h-[10%] h-full max-w-[50%] mb-1 ml-auto bg-white rounded-full"/>
                                                                <div
                                                                    className="block w-full max-h-[20%] h-full bg-white mb-1 rounded-full"/>
                                                                <div
                                                                    className="block w-full max-h-[10%] h-full max-w-[75%] mr-auto bg-white rounded-full"/>
                                                            </>
                                                        </Button>
                                                    </div>
                                                )}
                                                <div className="w-[120px] h-full debug">
                                                    <button onClick={giveMore} className="block w-full h-full bg-tertiary rounded-full -m-8
                                                         flex items-center justify-center gap-2 hover:bg-tertiary/90 transition-colors">
                                                        <span
                                                            className="text-sm text-white tracking-wider min-w-[60px] text-center">{menuOpen ? "Close" : "More"}</span>
                                                        <BiArrowFromLeft
                                                            className="bg-primary rounded-full !w-[20px] !h-[20px] flex-initial flex-col justify-center"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            case 30:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[100px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[100px] flex items-center justify-center">
                                            <div className="relative w-[320px] sm:w-[420px] bg-secondary rounded-full">
                                                {/* Logo and Nav Container */}
                                                <div className="relative py-4 pl-8">
                                                    {!hideHeaderLogo && (
                                                        <div className="relative">
                                                            <Link href="/" className="block w-[120px] sm:w-[160px]">
                                                                <img src="/imgs/full-logo.png" alt="logo"
                                                                     className="max-h-10 h-full w-auto hidden sm:block"/>
                                                                <img src="/imgs/icon.png" alt="logo"
                                                                     className="max-h-10 h-full w-auto block sm:hidden"/>
                                                            </Link>
                                                            <Button onClick={toggleMenu}
                                                                    className="absolute top-[50%] bg-tertiary pt-[2px] border-none
                                                                 transform -translate-y-[50%] rounded-sm -right-10
                                                                 h-6 w-8 flex flex-col justify-center">
                                                                <>
                                                                    <div className="block w-full max-h-[10%] h-full max-w-[50%] mb-1 ml-auto
                                                                      bg-white rounded-full"/>
                                                                    <div className="block w-full max-h-[20%] h-full bg-white mb-1
                                                                      rounded-full"/>
                                                                    <div className="block w-full max-h-[10%] h-full max-w-[75%] mr-auto
                                                                      bg-white rounded-full"/>
                                                                </>
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>

                                                {/* More Button - Positioned Absolutely */}
                                                <div id="action-button" className="absolute top-0 right-0 h-full">
                                                    <button onClick={giveMore}
                                                            className="h-full px-8 bg-tertiary rounded-r-full
                                                         flex items-center justify-center gap-2
                                                         hover:bg-tertiary/90 transition-colors">
                                            <span
                                                className="text-sm text-white tracking-wider min-w-[60px] text-center">
                                                {menuOpen ? "Close" : "More"}
                                            </span>
                                                        <BiArrowFromLeft
                                                            className="bg-primary rounded-full !w-[20px] !h-[20px]
                                                         flex-initial flex-col justify-center"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            case 31:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[100px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="relative w-full h-[100px] flex items-center justify-center debug">
                                        <div
                                            className="relative w-[320px] sm:w-[420px] bg-secondary rounded-full overflow-hidden">
                                            {/* Logo Container - Now centered */}
                                            {hideHeaderLogo && (
                                                <div
                                                    className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                                                    <div className="relative">
                                                        <Link href="/" className="block w-[120px] sm:w-[160px]">
                                                            <img src="/imgs/full-logo.png" alt="logo"
                                                                 className="max-h-10 h-full w-auto hidden sm:block"/>
                                                            <img src="/imgs/icon.png" alt="logo"
                                                                 className="max-h-10 h-full w-auto block sm:hidden"/>
                                                        </Link>
                                                        {/* Mobile nav icon - Maintained position relative to logo */}
                                                        <Button onClick={toggleMenu}
                                                                className="absolute top-[50%] bg-tertiary pt-[2px] border-none
                                                                 transform -translate-y-[50%] rounded-sm -right-10
                                                                 h-6 w-8 flex flex-col justify-center">
                                                            <>
                                                                <div className="block w-full max-h-[10%] h-full max-w-[50%] mb-1 ml-auto
                                                                      bg-white rounded-full"/>
                                                                <div className="block w-full max-h-[20%] h-full bg-white mb-1
                                                                      rounded-full"/>
                                                                <div className="block w-full max-h-[10%] h-full max-w-[75%] mr-auto
                                                                      bg-white rounded-full"/>
                                                            </>
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Action Button - Flush right */}
                                            <div id="action-button" className="absolute top-0 right-0 h-full">
                                                <button onClick={giveMore}
                                                        className="h-full bg-tertiary flex items-center gap-2 px-6
                                                         hover:bg-tertiary/90 transition-colors">
                                            <span className="text-sm text-white tracking-wider">
                                                {menuOpen ? "Close" : "More"}
                                            </span>
                                                    <BiArrowFromLeft
                                                        className="bg-primary rounded-full !w-[20px] !h-[20px]
                                                         flex-initial flex-col justify-center"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            // Variation A - Divided Sections
            case 33:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[90px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[90px] flex items-center justify-center">
                                            <div
                                                className="relative w-[320px] sm:w-[420px] grid grid-cols-3 bg-gradient-to-r from-secondary via-primary to-secondary rounded-xl overflow-hidden">
                                                {/* Logo Section */}
                                                {!hideHeaderLogo && (
                                                    <div className="col-span-2 relative flex items-center px-6 py-4">
                                                        <Link href="/" className="block">
                                                            <img src="/imgs/full-logo.png" alt="logo"
                                                                 className="h-10 w-auto hidden sm:block"/>
                                                            <img src="/imgs/icon.png" alt="logo"
                                                                 className="h-10 w-auto block sm:hidden"/>
                                                        </Link>
                                                        <Button onClick={toggleMenu}
                                                                className="absolute top-[50%] bg-tertiary pt-[2px] border-none
                                                             transform -translate-y-[50%] right-4 h-6 w-8
                                                             flex flex-col justify-center rounded-sm">
                                                            <>
                                                                <div className="block w-full max-h-[10%] h-full max-w-[50%] mb-1 ml-auto
                                                                  bg-white rounded-full"/>
                                                                <div
                                                                    className="block w-full max-h-[20%] h-full bg-white mb-1 rounded-full"/>
                                                                <div className="block w-full max-h-[10%] h-full max-w-[75%] mr-auto
                                                                  bg-white rounded-full"/>
                                                            </>
                                                        </Button>
                                                    </div>
                                                )}
                                                {/* Action Section */}
                                                <div className="bg-tertiary flex items-center justify-center">
                                                    <button onClick={giveMore}
                                                            className="h-full w-full flex items-center justify-center gap-2
                                                         hover:bg-tertiary/90 transition-colors px-4">
                                                        <span className="text-sm text-white tracking-wider">Flow</span>
                                                        <BiArrowFromLeft
                                                            className="bg-primary rounded-full !w-[20px] !h-[20px]"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Variation B - Soft Curves
            case 34:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[100px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[100px] flex items-center justify-center">
                                            <div
                                                className="relative w-[320px] sm:w-[420px] bg-secondary rounded-2xl overflow-hidden">
                                                {/* Logo Area */}
                                                {!hideHeaderLogo && (
                                                    <div
                                                        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                                                        <div className="relative">
                                                            <Link href="/" className="block">
                                                                <img src="/imgs/full-logo.png" alt="logo"
                                                                     className="h-10 w-auto hidden sm:block"/>
                                                                <img src="/imgs/icon.png" alt="logo"
                                                                     className="h-10 w-auto block sm:hidden"/>
                                                            </Link>
                                                            <Button onClick={toggleMenu}
                                                                    className="absolute top-[50%] bg-tertiary border-none
                                                                 transform -translate-y-[50%] -right-10 h-6 w-8
                                                                 flex flex-col justify-center rounded-sm pt-[2px]">
                                                                <>
                                                                    <div className="block w-full max-h-[10%] h-full max-w-[50%] mb-1 ml-auto
                                                                      bg-white rounded-full"/>
                                                                    <div
                                                                        className="block w-full max-h-[20%] h-full bg-white mb-1 rounded-full"/>
                                                                    <div className="block w-full max-h-[10%] h-full max-w-[75%] mr-auto
                                                                      bg-white rounded-full"/>
                                                                </>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* Action Button */}
                                                <div className="absolute top-0 right-0 h-full">
                                                    <button onClick={giveMore}
                                                            className="h-full bg-tertiary px-6 flex items-center gap-2
                                                         hover:bg-tertiary/90 transition-colors">
                                                        <span
                                                            className="text-sm text-white tracking-wider">Journey</span>
                                                        <BiArrowFromLeft
                                                            className="bg-primary rounded-full !w-[20px] !h-[20px]"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Variation cards - Sharp Edges
            case 35:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[90px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[90px] flex items-center justify-center">
                                            <div className="relative w-[320px] sm:w-[420px] bg-secondary">
                                                {/* Logo Section */}
                                                {!hideHeaderLogo && (
                                                    <div
                                                        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                                                        <div className="relative">
                                                            <Link href="/" className="block">
                                                                <img src="/imgs/full-logo.png" alt="logo"
                                                                     className="h-10 w-auto hidden sm:block"/>
                                                                <img src="/imgs/icon.png" alt="logo"
                                                                     className="h-10 w-auto block sm:hidden"/>
                                                            </Link>
                                                            <Button onClick={toggleMenu}
                                                                    className="absolute top-[50%] bg-tertiary border-none
                                                                 transform -translate-y-[50%] -right-10 h-6 w-8
                                                                 flex flex-col justify-center">
                                                                <>
                                                                    <div className="block w-full max-h-[10%] h-full max-w-[50%] mb-1 ml-auto
                                                                      bg-white rounded-full"/>
                                                                    <div
                                                                        className="block w-full max-h-[20%] h-full bg-white mb-1 rounded-full"/>
                                                                    <div className="block w-full max-h-[10%] h-full max-w-[75%] mr-auto
                                                                      bg-white rounded-full"/>
                                                                </>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* Action Area */}
                                                <div className="absolute top-0 right-0 h-full">
                                                    <button onClick={giveMore}
                                                            className="h-full bg-tertiary px-6 flex items-center gap-2
                                                         hover:bg-tertiary/90 transition-colors">
                                                        <span
                                                            className="text-sm text-white tracking-wider">Discover</span>
                                                        <BiArrowFromLeft
                                                            className="bg-primary rounded-full !w-[20px] !h-[20px]"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Variation D - Layered Design
            case 36:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[100px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[100px] flex items-center justify-center">
                                            <div className="relative w-[320px] sm:w-[420px]">
                                                {/* Background Layers */}
                                                <div
                                                    className="absolute inset-0 bg-tertiary/20 rounded-lg transform -rotate-1"/>
                                                <div className="absolute inset-0 bg-secondary rounded-lg"/>

                                                {/* Content */}
                                                <div className="relative h-full flex items-center justify-center py-4">
                                                    {!hideHeaderLogo && (
                                                        <div className="relative">
                                                            <Link href="/" className="block">
                                                                <img src="/imgs/full-logo.png" alt="logo"
                                                                     className="h-10 w-auto hidden sm:block"/>
                                                                <img src="/imgs/icon.png" alt="logo"
                                                                     className="h-10 w-auto block sm:hidden"/>
                                                            </Link>
                                                            <Button onClick={toggleMenu}
                                                                    className="absolute top-[50%] bg-tertiary border-none
                                                                 transform -translate-y-[50%] -right-10 h-6 w-8
                                                                 flex flex-col justify-center rounded-sm pt-[2px]">
                                                                <>
                                                                    <div className="block w-full max-h-[10%] h-full max-w-[50%] mb-1 ml-auto
                                                                      bg-white rounded-full"/>
                                                                    <div
                                                                        className="block w-full max-h-[20%] h-full bg-white mb-1 rounded-full"/>
                                                                    <div className="block w-full max-h-[10%] h-full max-w-[75%] mr-auto
                                                                      bg-white rounded-full"/>
                                                                </>
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>

                                                {/* Action Button */}
                                                <div className="absolute top-0 right-0 h-full">
                                                    <button onClick={giveMore}
                                                            className="h-full bg-tertiary rounded-r-lg px-6
                                                         flex items-center gap-2 hover:bg-tertiary/90
                                                         transition-colors">
                                                        <span
                                                            className="text-sm text-white tracking-wider">Explore</span>
                                                        <BiArrowFromLeft
                                                            className="bg-primary rounded-full !w-[20px] !h-[20px]"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            // Variant 33 Iterations - Menu Icon Treatments
            case 37:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[90px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[90px] flex items-center justify-center">
                                            <div className="relative w-[320px] sm:w-[420px] grid grid-cols-3
                                              bg-gradient-to-r from-secondary via-primary to-secondary rounded-xl overflow-hidden">
                                                {/* Logo Section */}
                                                {!hideHeaderLogo && (
                                                    <div className="col-span-2 relative flex items-center px-6 py-4">
                                                        <Link href="/" className="block">
                                                            <img src="/imgs/full-logo.png" alt="logo"
                                                                 className="h-10 w-auto hidden sm:block"/>
                                                            <img src="/imgs/icon.png" alt="logo"
                                                                 className="h-10 w-auto block sm:hidden"/>
                                                        </Link>
                                                        {/* Variation A: Subtle Background, Right Edge Aligned */}
                                                        <Button onClick={toggleMenu}
                                                                className="absolute top-[50%] bg-primary/20 backdrop-blur-sm
                                                             transform -translate-y-[50%] -right-2 h-7 w-8
                                                             flex flex-col justify-center rounded-l-sm">
                                                            <>
                                                                <div className="block w-full max-h-[10%] h-full max-w-[50%] mb-1 ml-auto
                                                                  bg-white/90 rounded-full"/>
                                                                <div
                                                                    className="block w-full max-h-[20%] h-full bg-white/90 mb-1 rounded-full"/>
                                                                <div className="block w-full max-h-[10%] h-full max-w-[75%] mr-auto
                                                                  bg-white/90 rounded-full"/>
                                                            </>
                                                        </Button>
                                                    </div>
                                                )}
                                                {/* Action Section */}
                                                <div className="bg-tertiary flex items-center justify-center">
                                                    <button onClick={giveMore}
                                                            className="h-full w-full flex items-center justify-center gap-2
                                                         hover:bg-tertiary/90 transition-colors px-4">
                                                        <span className="text-sm text-white tracking-wider">Flow</span>
                                                        <BiArrowFromLeft
                                                            className="bg-primary rounded-full !w-[20px] !h-[20px]"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            case 38:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[90px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[90px] flex items-center justify-center">
                                            <div className="relative w-[320px] sm:w-[420px] grid grid-cols-3
                                              bg-gradient-to-r from-secondary via-primary to-secondary rounded-xl overflow-hidden">
                                                {/* Logo Section */}
                                                {!hideHeaderLogo && (
                                                    <div className="col-span-2 relative flex items-center px-6 py-4">
                                                        <div className="relative">
                                                            <Link href="/" className="block">
                                                                <img src="/imgs/full-logo.png" alt="logo"
                                                                     className="h-10 w-auto hidden sm:block"/>
                                                                <img src="/imgs/icon.png" alt="logo"
                                                                     className="h-10 w-auto block sm:hidden"/>
                                                            </Link>
                                                            <Button onClick={toggleMenu}
                                                                    className="absolute top-[50%] bg-primary/20 backdrop-blur-sm
                                                                 transform -translate-y-[50%] -right-8 h-7 w-8
                                                                 flex flex-col justify-center rounded-l-sm">
                                                                <>
                                                                    <div className="block w-full max-h-[10%] h-full max-w-[50%] mb-1 ml-auto
                                                                      bg-white/90 rounded-full"/>
                                                                    <div
                                                                        className="block w-full max-h-[20%] h-full bg-white/90 mb-1 rounded-full"/>
                                                                    <div className="block w-full max-h-[10%] h-full max-w-[75%] mr-auto
                                                                      bg-white/90 rounded-full"/>
                                                                </>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* Action Section */}
                                                <div className="bg-tertiary flex items-center justify-center">
                                                    <button onClick={giveMore}
                                                            className="h-full w-full flex items-center justify-center gap-2
                                                         hover:bg-tertiary/90 transition-colors px-4">
                                                        <span className="text-sm text-white tracking-wider">Flow</span>
                                                        <BiArrowFromLeft
                                                            className="bg-primary rounded-full !w-[20px] !h-[20px]"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

            case 39:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[90px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[90px] flex items-center justify-center">
                                            <div className="relative w-[320px] sm:w-[420px] grid grid-cols-3
                                              bg-gradient-to-r from-secondary via-primary to-secondary rounded-xl overflow-hidden">
                                                {/* Logo Section */}
                                                {!hideHeaderLogo && (
                                                    <div className="col-span-2 relative flex items-center px-6 py-4">
                                                        <div className="relative">
                                                            <Link href="/" className="block">
                                                                <img src="/imgs/full-logo.png" alt="logo"
                                                                     className="h-10 w-auto hidden sm:block"/>
                                                                <img src="/imgs/icon.png" alt="logo"
                                                                     className="h-10 w-auto block sm:hidden"/>
                                                            </Link>
                                                            <Button onClick={toggleMenu}
                                                                    className="absolute top-[50%] bg-primary/20 backdrop-blur-sm
                                                                 transform -translate-y-[50%] -right-8 h-7 px-3
                                                                 flex items-center gap-2 rounded-full">
                                                                <>
                                                                    <span
                                                                        className="text-xs text-white tracking-wide">nav</span>
                                                                    <div
                                                                        className="flex flex-col justify-center gap-1 w-4">
                                                                        <div className="block w-full max-h-[10%] h-[2px] max-w-[50%] ml-auto
                                                                      bg-white/90 rounded-full"/>
                                                                        <div
                                                                            className="block w-full max-h-[20%] h-[2px] bg-white/90 rounded-full"/>
                                                                        <div className="block w-full max-h-[10%] h-[2px] max-w-[75%] mr-auto
                                                                      bg-white/90 rounded-full"/>
                                                                    </div>
                                                                </>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* Action Section */}
                                                <div className="bg-tertiary flex items-center justify-center">
                                                    <button onClick={giveMore}
                                                            className="h-full w-full flex items-center justify-center gap-2
                                                         hover:bg-tertiary/90 transition-colors px-4">
                                                        <span className="text-sm text-white tracking-wider">Flow</span>
                                                        <BiArrowFromLeft
                                                            className="bg-primary rounded-full !w-[20px] !h-[20px]"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );


            // Variant A - Subtle Nav Button
            case 40:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[90px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[90px] flex items-center justify-center">
                                            <div className="relative w-[320px] sm:w-[420px] flex items-center
                                              bg-gradient-to-r from-secondary via-primary to-secondary rounded-xl overflow-hidden">
                                                {/* Logo Section - Moved Left */}
                                                {!hideHeaderLogo && (
                                                    <div className="pl-6 py-4">
                                                        <Link href="/" className="block">
                                                            <img src="/imgs/full-logo.png" alt="logo"
                                                                 className="h-10 w-auto hidden sm:block"/>
                                                            <img src="/imgs/icon.png" alt="logo"
                                                                 className="h-10 w-auto block sm:hidden"/>
                                                        </Link>
                                                    </div>
                                                )}

                                                {/* Navigation Group - Right Side */}
                                                <div className="flex items-center gap-3 ml-auto pr-3">
                                                    {/* Nav Button */}
                                                    <Button onClick={toggleMenu}
                                                            className="bg-primary/20 backdrop-blur-sm h-7 px-3
                                                         flex items-center gap-2 rounded-full">
                                                        <>
                                                            <span
                                                                className="text-xs text-white tracking-wide">nav</span>
                                                            <div className="flex flex-col justify-center gap-1 w-4">
                                                                <div className="block w-full h-[2px] max-w-[50%] ml-auto
                                                              bg-white/90 rounded-full"/>
                                                                <div
                                                                    className="block w-full h-[2px] bg-white/90 rounded-full"/>
                                                                <div className="block w-full h-[2px] max-w-[75%] mr-auto
                                                              bg-white/90 rounded-full"/>
                                                            </div>
                                                        </>
                                                    </Button>

                                                    {/* More Button */}
                                                    <button onClick={giveMore}
                                                            className="bg-tertiary h-full py-4 px-4 flex items-center gap-2
                                                         hover:bg-tertiary/90 transition-colors">
                                                        <span className="text-sm text-white tracking-wider">Flow</span>
                                                        <BiArrowFromLeft
                                                            className="bg-primary rounded-full !w-[20px] !h-[20px]"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );

// Variant B - Matching Nav Button Style
            case 41:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-gray-700 !text-white mt-[100px]">{menu}</div>
                                </div>

                                <div className="bg-pattern-3 bg-gray-700 p-2">
                                    <div className="bg-secondary">
                                        <div className="max-w-6xl mx-auto">
                                            <div className="p-2 flex items-center justify-between">
                                                {/* Logo Section - Moved Left */}
                                                {!hideHeaderLogo && (
                                                    <div className="bg-pattern-3 bg-gray-700 p-0 rounded-full">
                                                        <div className="p-3 rounded-full">
                                                            <Link href="/" className="block">
                                                                <img src="/imgs/full-logo.png" alt="logo"
                                                                     className="h-10 w-auto hidden sm:block"/>
                                                                <img src="/imgs/icon.png" alt="logo"
                                                                     className="h-10 w-auto block sm:hidden"/>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="flex-1">&nbsp;</div>

                                                <div
                                                    className="bg-pattern-3 bg-gray-700 p-0 rounded-full max-h-[100px]">
                                                    <div
                                                        className="relative border-none flex items-center rounded-full overflow-hidden gap-1">
                                                        {/* Nav Button - Matching Style */}
                                                        <Button onClick={toggleMenu}
                                                                variant={'link' as any}
                                                                className="h-full hover:!bg-tertiary py-4 px-4 flex items-center gap-2 group rounded-l-full border-none">
                                                            <>
                                                                <span
                                                                    className="font-bold text-lg tracking-wider text-white group-hover:text-black">nav</span>
                                                                <div
                                                                    className=" !w-[20px] !h-[20px] rounded-full bg-secondary">
                                                                    <div
                                                                        className="flex flex-col justify-center gap-1 w-4 m-auto !w-[18px] !h-[18px]">
                                                                        <div
                                                                            className="block w-full h-[2px] max-w-[50%] ml-auto bg-white group-hover:bg-black rounded-full"/>
                                                                        <div
                                                                            className="block w-full h-[2px] bg-white rounded-full group-hover:bg-black"/>
                                                                        <div
                                                                            className="block w-full h-[2px] max-w-[75%] mr-auto bg-white rounded-full group-hover:bg-black"/>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        </Button>

                                                        {/*<Button onClick={giveMore}*/}
                                                        {/*        className="bg-primary h-full py-4 px-4 flex items-center gap-2*/}
                                                        {/* hover:bg-secondary transition-colors rounded-r-full border-none group">*/}
                                                        {/*    <>*/}
                                                        {/*    <span*/}
                                                        {/*        className="text-lg font-bold text-white tracking-wider group-hover:text-black">Flow</span>*/}
                                                        {/*        <BiArrowFromLeft*/}
                                                        {/*            className="bg-secondary fill-white rounded-full !w-[20px] !h-[20px] group-hover:fill-black"/>*/}
                                                        {/*    </>*/}
                                                        {/*</Button>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal className="w-full flex flex-col sm:flex-row sm:w-auto gap-2"
                               hasTransparentBackground={true}
                               isOpen={isProfileMenuModalOpen}
                               onClose={() => setIsProfileMenuModalOpen(false)}>
                            <p>xxx</p>
                        </Modal>
                    </section>
                );

            case 42:
                return (
                    <section className="mb-8">
                        <div ref={headerRef} className={cx("w-full max-w-full", className)}>
                            <div className={cx("w-full", {"stuck": isSticky})} style={{maxWidth: navWidth}}>
                                <div ref={menuRef} className={cx("w-full absolute z-20 hidden")}>
                                    <div className="bg-secondary mt-[90px]">
                                        <div className="max-w-6xl mx-auto p-8">{menu}</div>
                                    </div>
                                </div>

                                <div className="bg-pattern-3 bg-primary">
                                    <div className="max-w-6xl mx-auto">
                                        <div className="h-[90px] flex items-center justify-center">
                                            <div className="w-[320px] sm:w-[420px] flex rounded-xl overflow-hidden">
                                                {/* Logo Section - Left Side */}
                                                <div className="bg-secondary py-4 pl-6 pr-12">
                                                    {!hideHeaderLogo && (
                                                        <Link href="/" className="block">
                                                            <img src="/imgs/full-logo.png" alt="logo"
                                                                 className="h-10 w-auto hidden sm:block"/>
                                                            <img src="/imgs/icon.png" alt="logo"
                                                                 className="h-10 w-auto block sm:hidden"/>
                                                        </Link>
                                                    )}
                                                </div>

                                                {/* Navigation Group - Right Side */}
                                                <div className="flex items-center bg-primary ml-auto">
                                                    {/* Nav Button */}
                                                    <Button onClick={toggleMenu}
                                                            className="bg-primary/20 backdrop-blur-sm h-7 px-3
                                                         flex items-center gap-2 rounded-full mr-3">
                                                        <>
                                                            <span
                                                                className="text-xs text-white tracking-wide">nav</span>
                                                            <div className="flex flex-col justify-center gap-1 w-4">
                                                                <div className="block w-full h-[2px] max-w-[50%] ml-auto
                                                              bg-white/90 rounded-full"/>
                                                                <div
                                                                    className="block w-full h-[2px] bg-white/90 rounded-full"/>
                                                                <div className="block w-full h-[2px] max-w-[75%] mr-auto
                                                              bg-white/90 rounded-full"/>
                                                            </div>
                                                        </>
                                                    </Button>

                                                    {/* More Button */}
                                                    <button onClick={giveMore}
                                                            className="bg-tertiary h-full py-4 px-4 flex items-center gap-2
                                                         hover:bg-tertiary/90 transition-colors">
                                                        <span className="text-sm text-white tracking-wider">Flow</span>
                                                        <BiArrowFromLeft
                                                            className="bg-primary rounded-full !w-[20px] !h-[20px]"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            default:
                return <p>No variant found.</p>;
        }
    };

    return renderDesign();
}