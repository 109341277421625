import cx from "classnames";
import React, { ChangeEvent, forwardRef } from "react";

export interface InputProps {
    className: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    value: string;
    type: string;

    [key: string]: any;
}

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, onChange, type = 'text', value, defaultValue, ...rest }, ref) => {
    return (
        <input
            type={type}
            value={value}
            defaultValue={defaultValue}
            ref={ref}
            className={cx("p-1 rounded-sm bg-gray-700", { 'rangeSlider': type === 'range' }, { 'input-checkbox': type === 'checkbox' }, className)}
            onChange={onChange}
            {...rest}
        />
    );
});

Input.displayName = 'Input';

export default Input;