import React, {useRef, useState} from "react";
import {useSiteMessage} from "../../providers/SiteMessageProvider";
import Loading from "../../components/Loading/Loading";
import {apiPost} from "../../services/apiService";
import {SiteMessageEnum} from "@varymadellc/my-weed-club-component-library";
import {useAuth} from "../../providers/AuthProvider";
import ImageUploadEditor from "../../components/ImageUploadEditor";
import {useChatSocketProvider} from "../../providers/ChatSocketProvider";
import {b} from "vite/dist/node/types.d-aGj9QkWt";

export default function ChatModal({setIsOpen}: { setIsOpen: (bool: boolean) => void }) {
    const {socket} = useChatSocketProvider();

    const [chatImage, setChatImage] = useState<{ image?: any, name?: string } | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {authToken} = useAuth();

    const {setMessage} = useSiteMessage();

    const fileRef = useRef<any>();

    const reset = () => {
        setMessage(null);
        setChatImage(null);

        if (fileRef.current) {
            fileRef.current.value = null;
        }

        setIsOpen(false);
    };

    // TODO move to a hook, shared with Profile Modal
 const onUpload = async (image: string, name: string): Promise<boolean> => {
        setIsLoading(true);

        if (!name) {
            setMessage('The image name is not set', SiteMessageEnum.error);
            setIsLoading(false);
            return false;
        }

        if (!image) {
            setMessage('Please select an image to upload', SiteMessageEnum.error);
            setIsLoading(false);
            return false;
        }

        const imageData = await fetch(image);
        const blob = await imageData.blob();
        const formData = new FormData();
        formData.append('name', 'payload');
        formData.append('file', blob, name);

        try {
            const response = await apiPost('/upload?type=chat-image', formData, authToken, {'Content-Type': false});
            if (response.ok) {
                setIsLoading(false);
                const json = await response.json();
                if (json.error) {
                    setMessage(json.error, SiteMessageEnum.error);
                    setIsLoading(false);
                    return false;
                }

                // Callback Op
                if (!socket.connected) {
                    await socket.connect();
                }

                await socket.emit('chatImage', json.filePathOrUrl)

                reset();

                setIsLoading(false);

                setMessage('Image posted');

                return true;
            } else {
                setMessage('Failed at this time', SiteMessageEnum.error);
                setIsLoading(false);
                reset();

                return false;
            }
        } catch (error) {
            console.error(error);
            setMessage('Unable to upload your imageData at this time.', SiteMessageEnum.error);

            return false;
        }
    };

    return (
        <div className="mx-auto flex flex-col">
            <h2>Upload Image</h2>
            <div className="relative flex flex-col p-2">
                {isLoading && <Loading hasOverlay={true}/>}
                {
                    chatImage &&
                    <ImageUploadEditor
                        rounded={false}
                        onReset={reset}
                        onUpload={onUpload}
                        setIsLoading={setIsLoading}
                    />
                }
            </div>
        </div>
    );
}