import ReactModal from "react-modal";
import {FiX} from "react-icons/fi";
import {ReactNode, useEffect} from "react";
import cx from "classnames";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode | ReactNode[];
    title?: string;
    hasTransparentBackground?: boolean;
    hasTransparentOverlay?: boolean;
    preventScroll?: boolean;
    hideCloseButton?: boolean;
    afterOpen?: () => void;
    afterClose?: () => void;
    className?: string;
}


export default function Modal({
                                  isOpen = true,
                                  afterOpen,
                                  onClose,
                                  children,
                                  title,
                                  preventScroll = false,
                                  hasTransparentBackground,
                                  hasTransparentOverlay = false,
                                  hideCloseButton = false,
                                  className
                              }: ModalProps) {
    useEffect(() => {
        ReactModal.setAppElement('#root');
    }, []);

    return (
        <ReactModal
            isOpen={isOpen}
            preventScroll={preventScroll}
            onAfterOpen={afterOpen}
            onRequestClose={onClose}
            style={{
                overlay: {zIndex: 1000000, backgroundColor: `rgba(0,0,0,${hasTransparentOverlay ? '0' : '0.7'})`},
                content: {backgroundColor: hasTransparentBackground ? 'transparent' : undefined}
            }}
            contentLabel="Modal"
            overlayClassName="modal-overlay"
            className={cx("outline-none w-full !max-w-[1200px] mx-auto sm:m-2", className)}
        >
            <div className="modal-content m-2 w-auto">
                {title && <h2 className="modal-title pb-4">{title}</h2>}
                {children}
                {!hideCloseButton && <FiX
                    className={cx("modal-close-button z-100", {"hasTransparentBackground": hasTransparentBackground})}
                    onClick={onClose}/>}
            </div>
        </ReactModal>
    );
}